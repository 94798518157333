@font-face {
  font-family: halebopmedium;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.svg#halebopmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: haleboplight;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.svg#haleboplight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: halebopxtrabold;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.svg#halebopxtrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-layer-background: #f3f7f8;
  --second-layer-background: #fff;
  --line-color: #abacaf;
  --primary-text-color: #fff;
  --primary-hover-color: #2a2a2b;
  --primary-color: #2a2a2b;
  --font-family: "halebopmedium";
  --purpur-color-text-interactive-on-expressive: #fff;
  --purpur-color-background-interactive-expressive: #2a2a2b;
  --purpur-color-background-interactive-expressive-hover: #2a2a2b;
  --purpur-color-background-interactive-primary: #2a2a2b;
  --purpur-color-text-interactive-on-primary: #fff;
  --purpur-color-text-interactive-primary: #2a2a2b;
  --purpur-color-background-interactive-primary-hover: #2a2a2b;
  --purpur-color-background-interactive-primary-active: #abacaf;
  --purpur-color-background-interactive-disabled: #eff2f5;
  --purpur-color-border-interactive-primary: #2a2a2b;
  --purpur-color-background-interactive-transparent-hover: #eff2f5;
  --purpur-color-background-interactive-transparent-active: #eff2f5;
  --purpur-color-text-default: #222;
  --purpur-color-border-interactive-subtle: #c5d6ec;
  --purpur-color-border-interactive-primary-hover: #0f0;
  --purpur-color-background-tone-on-tone-secondary: #736ee0;
  --purpur-color-background-primary: #fff;
  --purpur-color-brand-core-purple: #2a2a2b;
  --purpur-color-text-interactive-selected: #2a2a2b;
  --purpur-color-border-interactive-focus: #2a2a2b;
}

/*# sourceMappingURL=index.a36f77f0.css.map */
