@font-face {
  font-family: 'halebopmedium';
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.svg#halebopmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'haleboplight';
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_light-webfont.svg#haleboplight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'halebopxtrabold';
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_xtrabold-webfont.svg#halebopxtrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  /*main background color*/
  --main-layer-background: rgb(243, 247, 248);
  /* alla element som ligger i "second" layer och har en bakgrundsfärg (Effektiv ränta exempel)*/
  --second-layer-background: #fff;
  /* Vi har i vissa vyer en line separator så denna ändrar färg på denna*/
  --line-color: #ABACAF;
  /* Ändrar alla primary element som har text i sig notera att tex text länkar fortfarande lyssnar på --primary-color. Detta är mest för primary knappar */
  --primary-text-color: #fff;
  --primary-hover-color: #2A2A2B;
  /* Used for Loading dots */
  --primary-color: #2A2A2B;


  /* To apply your @font-face font family */
  --font-family: 'halebopmedium';

  /** NEW */
  --purpur-color-text-interactive-on-expressive: #fff;
  --purpur-color-background-interactive-expressive: #2A2A2B;
  --purpur-color-background-interactive-expressive-hover: #2A2A2B;

  --purpur-color-background-interactive-primary: #2A2A2B;
  --purpur-color-text-interactive-on-primary: #fff;

  --purpur-color-text-interactive-primary: #2A2A2B;
  --purpur-color-background-interactive-primary-hover: #2A2A2B;
  --purpur-color-background-interactive-primary-active: #ABACAF;

  --purpur-color-background-interactive-disabled: #EFF2F5;

  --purpur-color-border-interactive-primary: #2A2A2B;
  --purpur-color-background-interactive-transparent-hover: #EFF2F5;

  --purpur-color-background-interactive-transparent-active: #EFF2F5;

  --purpur-color-text-default: #222222;
  --purpur-color-border-interactive-subtle: #c5d6ec;
  --purpur-color-border-interactive-primary-hover: #0f0;

  --purpur-color-background-tone-on-tone-secondary: #736ee0;

  --purpur-color-background-primary: #fff;

  --purpur-color-brand-core-purple: #2A2A2B;

  --purpur-color-text-interactive-selected: #2A2A2B;

  --purpur-color-border-interactive-focus: #2A2A2B;
}